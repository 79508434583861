/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { useSpring, animated } from "@react-spring/three";

function Box({ ...props }) {
  // This reference will give us direct access to the mesh
  const mesh = useRef();
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);

  // Return view, these are regular three.js elements expressed in JSX
  const { scale } = useSpring({ scale: active ? 1.5 : 1 });

  let mouseX = 0;
  let TargetX = 0;

  const windowHalfX = window.innerWidth / 2;

  document.addEventListener("mousemove", (e) => {
    mouseX = e.clientX - windowHalfX;
  });

  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame(({ controls, clock, camera }) => {
    TargetX = mouseX * 0.00015;
    const elapsedTime = clock.getElapsedTime();
    mesh.current.rotation.y += 0.5 * (TargetX - mesh.current.rotation.y);
    mesh.current.rotation.x += 0.001;
  }, []);

  return (
    <animated.mesh
      {...props}
      ref={mesh}
      scale={scale}
      onClick={(event) => setActive(!active)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? "hotpink" : "orange"} />
    </animated.mesh>
  );
}

export default Box;
