import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { WavyContainer } from "react-wavy-transitions";
import Layout from "./pages/Layout";
import Start from "./pages/Start";
import React, { useEffect, useState } from "react";
import getMenuData from "./services/getMenuData";
import getPagesData from "./services/getPagesData";
import getPageData from "./services/getPageData";
import Article from "./pages/Article";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [routesData, setRoutesData] = useState([]);
  const [pagesData, setPagesData] = useState([]);
  const [pagesInMenuData, setPagesInMenuData] = useState([]);

  function filteredPages(menuObj, pagesObj) {
    let results = pagesObj.filter((o1) =>
      menuObj.some((o2) => o1.link === o2.url)
    );
    return results;
  }

  useEffect(() => {
    var menuD;
    var pagesD;
    getMenuData()
      .then((data) => {
        menuD = data;
        setRoutesData(data);
        setLoading(false);
      })
      .then(() => {
        getPagesData()
          .then((data) => {
            pagesD = data;
            setPagesData(data);
            setLoading(false);
          })
          .then(() => {
            setPagesInMenuData(filteredPages(menuD, pagesD));
            setLoading(false);
          });
      });
  }, []);

  return (
    <BrowserRouter>
      <WavyContainer />
      <Routes>
        <Route path="/" element={<Layout routesData={pagesInMenuData} />}>
          {!loading && <Route index element={<Start />} />}
          {!loading &&
            pagesInMenuData.map((item, index) => {
              if (item.template === "full_width_page.php") {
                return (
                  <Route
                    key={item.title.rendered + index}
                    path={item.title.rendered.replace(/\s/g, "-").toLowerCase()}
                    element={<Article {...item} />}
                  ></Route>
                );
              } else {
                return (
                  <Route
                    key={item.title.rendered + index}
                    path={item.title.rendered.replace(/\s/g, "-").toLowerCase()}
                    element={<Contact {...item} />}
                  />
                );
              }
            })}
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
      <footer className="footer py-3">
        <div className="container">
          <span>Patrik Englund © 2022</span>
        </div>
      </footer>
    </BrowserRouter>
  );
};

export default App;
