import React, { Suspense, useRef, useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import Box from "../components/Box";
import Model from "../components/Model";
import Model2 from "../components/Model2";
import Controls from "../components/Controls";
import { useSpring, animated } from "@react-spring/three";
import axios from "axios";
import Title from "../components/Title";
import Posts from "../components/Posts";
import Pages from "../components/Pages";
import { Outlet, Link } from "react-router-dom";
import anime from "animejs/lib/anime.es.js";

const Start = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [hideLoader, setHideLoader] = useState(false);
  const [modelPosition, setModelPosition] = useState([1.5, -1.5, 4.5]);

  useEffect(() => {
    //handle model position
    const handleModelPosition = () => {
      if (window.innerWidth > 1024) {
        setModelPosition([1.5, -1.5, 4.5]);
      } else if (window.innerWidth > 768) {
        setModelPosition([1, -1.5, 3.5]);
      } else if (window.innerWidth < 415) {
        setModelPosition([1.1, -1.6, 4]);
      }
    };
    //handle model position on load
    handleModelPosition();
    //Check resize hand handle model position
    const handleResize = () => {
      handleModelPosition();
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    //Anime animation
    var animeAnimation = anime
      .timeline({ loop: false })
      .add({
        targets: ".ml8 .circle-white",
        scale: [0, 3],
        opacity: [1, 0],
        easing: "easeInOutExpo",
        rotateZ: 360,
        duration: 1100,
        autoplay: false,
      })
      .add({
        targets: ".ml8 .circle-container",
        scale: [0, 1],
        duration: 1100,
        easing: "easeInOutExpo",
        offset: "-=1000",
        begin: function () {
          document.querySelector(".ml8 .circle-dark").style.opacity = 1;
        },
      })
      .add({
        targets: ".ml8 .circle-dark",
        scale: [0, 1],
        duration: 1100,
        easing: "easeOutExpo",
        offset: "-=600",
        begin: function () {
          document.querySelector(".ml8 .letters-container").style.opacity = 1;
        },
      })
      .add({
        targets: ".ml8 .letters-left",
        scale: [0, 1],
        duration: 1200,
        offset: "-=550",
      });

    var animeAnimation2 = anime({
      targets: ".ml8 .circle-dark-dashed",
      rotateZ: 360,
      duration: 8000,
      easing: "linear",
      loop: true,
    });
  }, []);

  useEffect(() => {
    //fetch data
    const fetchData = async () => {
      try {
        axios
          .get("https://riku.se/wp-json/")
          .then(function (response) {
            //Wait for loading animation to finish
            setTimeout(function () {
              setData(response.data);
            }, 6000);
            console.log(response);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            hideLoaders();
          });
        //setData(response);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  function hideLoaders() {
    setTimeout(function () {
      setHideLoader(true);
      setTimeout(function () {
        setLoading(false);
      }, 500);
    }, 6000);
  }

  // .add({
  //   targets: ".ml8 .bang",
  //   scale: [0, 1],
  //   rotateZ: [45, 15],
  //   duration: 1200,
  //   offset: "-=1000",
  // });
  // .add({
  //   targets: ".ml8",
  //   opacity: 0,
  //   duration: 1000,
  //   easing: "easeOutExpo",
  //   delay: 1400,
  // });

  // function removeAnimation() {
  //   console.log("test222");
  //   animeAnimation.pause();
  //   animeAnimation2.pause();
  //   animeAnimation.reset();
  //   animeAnimation2.reset();
  // }
  let startCameraPosition = [2, 0, 8];
  return (
    <div className="App-header container start-page">
      {loading && (
        <section
          className={
            hideLoader
              ? "home-loading-container fade"
              : "home-loading-container"
          }
        >
          <h1 className="ml8">
            <span className="letters-container">
              <span className="letters letters-left">Hello!</span>
            </span>
            <span className="circle circle-white"></span>
            <span className="circle circle-dark"></span>
            <span className="circle circle-container">
              <span className="circle circle-dark-dashed"></span>
            </span>
          </h1>
        </section>
      )}
      <div>
        <section className="row min-vh-100">
          <div className="col-12 col-lg-4">
            <div className="d-flex align-items-center h-100">
              <div className="card">
                <div className="card-body text-dark">
                  <h1 className="card-title">Patrik Englund</h1>
                  <h2 className="fst-italic">Web Developer</h2>
                  <p className="card-text">
                    I am Patrik - a driven, effective and creative Web Developer
                    based in Stockholm, Sweden.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="start-3d">
            <Canvas
              className="start-gradient"
              camera={{
                position: startCameraPosition,
                fov: 15,
              }}
            >
              <Controls></Controls>
              <ambientLight intensity={1.25} />
              <directionalLight intensity={1.4} />
              <Suspense fallback={null}>
                <Model position={modelPosition}></Model>
              </Suspense>
              <Box position={[-4, 1, -5]} />
              <Box position={[-2, 1, -5]} />
              <Box position={[0, 1, -5]} />
              <Box position={[2, 1, -5]} />
              <Box position={[-4, -1, -5]} />
              <Box position={[-2, -1, -5]} />
              <Box position={[0, -1, -5]} />
              <Box position={[2, -1, -5]} />
            </Canvas>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Start;
