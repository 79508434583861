import React, { Suspense, useRef, useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import Box from "../components/Box";
import Model from "../components/Model";
import Model2 from "../components/Model2";
import Controls from "../components/Controls";
import { useSpring, animated } from "@react-spring/three";
import axios from "axios";
import Title from "../components/Title";
import Posts from "../components/Posts";
import Pages from "../components/Pages";
import { Outlet, Link } from "react-router-dom";
import anime from "animejs/lib/anime.es.js";
let startCameraPosition = [2, 0, 8];
const Contact = (props) => {
  return (
    <div className="container contact-page">
      <Canvas
        className="messagemodel"
        camera={{
          position: startCameraPosition,
          fov: 45,
        }}
      >
        <Controls></Controls>
        <ambientLight intensity={1.25} />
        <directionalLight intensity={1.4} />
        <Suspense fallback={null}>
          <Model2 position={[-0.5, 0, -3]}></Model2>
        </Suspense>
      </Canvas>
      <div className="row d-flex justify-content-center align-items-center min-vh-100">
        <div className="col-12 col-md-6 offset-md-6">
          <div className="card">
            <div className="card-header border-0 bg-transparent">
              <h1 className="article-heading text-color-indigo bg-transparent">
                {props.title.rendered}
              </h1>
            </div>
            <div className="card-body">
              <div
                dangerouslySetInnerHTML={{ __html: props.content.rendered }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
