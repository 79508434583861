import React, { useRef, useState } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { useGLTF, Html } from "@react-three/drei";
import { useSpring, animated } from "@react-spring/three";
import * as THREE from "three";

const vec = new THREE.Vector3();

const Controls = (props) => {
  const [position, setPosition] = useState({ x: 2, y: 0, z: 8 });
  const [position2, setPosition2] = useState("hejhej");

  useFrame(({ clock, camera }) => {
    camera.position.lerp(vec.set(position.x, position.y, position.z), 0.08);
    // camera.quaternion.slerp(0, 0, 0, 0.08);
    camera.updateMatrixWorld();
    // console.log(camera);
  }, []);

  window.addEventListener("keydown", (e) => {
    let keyCode = e.key;
    switch (keyCode) {
      case "ArrowUp":
        setPosition({ ...position, y: position.y + 1 });
        break;

      case "ArrowDown":
        setPosition({ ...position, y: position.y - 1 });
        break;
      case "ArrowLeft":
        setPosition({ ...position, x: position.x - 1 });
        break;
      case "ArrowRight":
        setPosition({ ...position, x: position.x + 1 });
        break;
      case "+":
        setPosition({ ...position, z: position.z - 1 });
        break;
      case "-":
        setPosition({ ...position, z: position.z + 1 });
        break;
    }
  });
  return (
    <Html as="div" transform="false" position={position}>
      <div className="controls" style={{ display: "none" }}>
        <label>x</label>
        <input
          onChange={(e) => setPosition({ ...position, x: e.target.value })}
          value={position.x}
          type="number"
        />
        <label>y</label>
        <input
          onChange={(e) => setPosition({ ...position, y: e.target.value })}
          value={position.y}
          type="number"
        />
        <label>z</label>
        <input
          onChange={(e) => setPosition({ ...position, z: e.target.value })}
          value={position.z}
          type="number"
        />
      </div>
      <div>
        Camera position {position.x} {position.y} {position.z}
      </div>
    </Html>
  );
};

export default Controls;
