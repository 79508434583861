import axios from "axios";

//For handling promise
const getWithAxios = axios
  .get("https://riku.se/wp-json/wp/v2/pages")
  .then((response) => {
    return response.data;
  });

const getPagesData = async () => {
  const menuData = await getWithAxios;
  return menuData;
};

export default getPagesData;
