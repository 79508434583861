/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useSpring, animated } from "@react-spring/three";
import * as THREE from "three";

function Model2({ ...props }) {
  const group = useRef();
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);
  const messageModel = useGLTF("/models/message.glb");
  // const messageModel = useGLTF("/models/uploads_files_3784260_Laptop.glb");
  const vec = new THREE.Vector3();

  let mouseX = 0;
  let TargetX = 0;

  const windowHalfX = window.innerWidth / 2;

  document.addEventListener("mousemove", (e) => {
    mouseX = e.clientX - windowHalfX;
  });

  useFrame(({ controls, clock, camera }) => {
    TargetX = mouseX * 0.001;
    const elapsedTime = clock.getElapsedTime();
    messageModel.scene.children[0].rotation.z = 0.8 * elapsedTime;
    messageModel.scene.children[0].rotation.y +=
      0.5 * (TargetX - messageModel.scene.children[0].rotation.y);
  }, []);

  window.addEventListener("keydown", (e) => {
    let keyCode = e.key;
    // console.log(e.key);
    switch (keyCode) {
      case "1":
        group.current.rotation.y += 0.5;
        break;

      case "2":
        group.current.rotation.y -= 0.05;
        break;
    }
  });

  return (
    <group
      {...props}
      dispose={null}
      ref={group}
      onClick={() => {
        setActive(!active);
      }}
      onPointerOver={() => setHover(true)}
      onPointerOut={() => setHover(false)}
    >
      <primitive object={messageModel.scene} />
    </group>
  );
}

export default Model2;
