/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import { useGLTF, useAnimations } from "@react-three/drei";
import { animated } from "@react-spring/three";
import * as THREE from "three";

function Model({ ...props }) {
  const group = useRef();
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);
  const [rotateLeft, setRotateLeft] = useState(false);
  const [rotateRight, setRotateRight] = useState(false);
  const { nodes, materials, animations } = useGLTF("/model-waving.glb");
  const vec = new THREE.Vector3();

  const { actions } = useAnimations(animations, group); /* highlight-line */
  // 'Armature|mixamo.com|Layer0' is the name of the animation we need to run.

  useEffect(() => {
    actions["Armature|mixamo.com|Layer0"].reset();
    const intervalWave = setInterval(function () {
      console.log("wave");
      actions["Armature|mixamo.com|Layer0"].setLoop(THREE.LoopOnce);
      actions["Armature|mixamo.com|Layer0"].play();
      actions["Armature|mixamo.com|Layer0"].reset();
    }, 15000);

    return () => {
      clearInterval(intervalWave); //This is important
    };
  });

  useFrame(({ controls, clock, camera }) => {
    // if (active) {
    //   actions["Armature|mixamo.com|Layer0"].setLoop(THREE.LoopOnce);
    //   actions["Armature|mixamo.com|Layer0"].play();
    //   actions["Armature|mixamo.com|Layer0"].reset();
    // }
  }, []);

  window.addEventListener("keydown", (e) => {
    let keyCode = e.key;
    // console.log(e.key);
    switch (keyCode) {
      case "1":
        group.current.rotation.y += 0.5;
        break;

      case "2":
        group.current.rotation.y -= 0.05;
        break;
    }
  });

  return (
    <group {...props} dispose={null}>
      <animated.mesh
        ref={group}
        onClick={() => {
          setActive(!active);
        }}
        onPointerOver={() => setHover(true)}
        onPointerOut={() => setHover(false)}
      >
        <primitive object={nodes.Hips} />
        <skinnedMesh
          geometry={nodes.Wolf3D_Body.geometry}
          material={materials.Wolf3D_Body}
          skeleton={nodes.Wolf3D_Body.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Hair.geometry}
          material={materials.Wolf3D_Hair}
          skeleton={nodes.Wolf3D_Hair.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
          material={materials.Wolf3D_Outfit_Bottom}
          skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
          material={materials.Wolf3D_Outfit_Footwear}
          skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Outfit_Top.geometry}
          material={materials.Wolf3D_Outfit_Top}
          skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
        />
        <skinnedMesh
          name="EyeLeft"
          geometry={nodes.EyeLeft.geometry}
          material={materials.Wolf3D_Eye}
          skeleton={nodes.EyeLeft.skeleton}
          morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
          morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
        />
        <skinnedMesh
          name="EyeRight"
          geometry={nodes.EyeRight.geometry}
          material={materials.Wolf3D_Eye}
          skeleton={nodes.EyeRight.skeleton}
          morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
          morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
        />
        <skinnedMesh
          name="Wolf3D_Head"
          geometry={nodes.Wolf3D_Head.geometry}
          material={materials.Wolf3D_Skin}
          skeleton={nodes.Wolf3D_Head.skeleton}
          morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
          morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
        />
        <skinnedMesh
          name="Wolf3D_Teeth"
          geometry={nodes.Wolf3D_Teeth.geometry}
          material={materials.Wolf3D_Teeth}
          skeleton={nodes.Wolf3D_Teeth.skeleton}
          morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
          morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
        />
      </animated.mesh>
    </group>
  );
}

export default Model;
