const Article = (props) => {
  return (
    <div className="container article-page">
      <div className="row">
        <div className="col-12 col-lg-10 col-xl-8">
          <div className="card">
            <div className="card-header border-0 bg-transparent">
              <h1 className="article-heading text-color-indigo bg-transparent">
                {props.title.rendered}
              </h1>
            </div>
            <div className="card-body">
              <div
                dangerouslySetInnerHTML={{ __html: props.content.rendered }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;
