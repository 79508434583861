import axios from "axios";

//For handling promise
const getWithAxios = axios
  .get("https://riku.se/wp-json/wp/v2/menu")
  .then((response) => {
    return response.data;
  });

const getMenuData = async () => {
  const menuData = await getWithAxios;
  return menuData;
};

export default getMenuData;
