const NoPage = () => {
  return (
    <div className="container article-page">
      <div className="row">
        <div className="col-12 col-lg-10 col-xl-8">
          <h1 className="article-heading text-white">No Page :(</h1>
          <a href="/" className="link-light">
            Go Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default NoPage;
