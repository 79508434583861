import { Outlet, Link } from "react-router-dom";
import { WavyLink } from "react-wavy-transitions";
import React, { useEffect, useState } from "react";
import axios from "axios";

const Layout = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await axios.get(
          "https://riku.se/wp-json/wp/v2/menu"
        );
        setData(response);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, []);
  return (
    <>
      <nav className="navbar navbar-dark position-fixed">
        <div className="container-fluid">
          <a
            className="navbar-toggler"
            data-bs-toggle="offcanvas"
            href="#offcanvas"
            role="button"
            aria-controls="offcanvas"
          >
            <span className="navbar-toggler-icon"></span>
          </a>
          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvas"
            aria-labelledby="offcanvasLabel"
          >
            <div className="offcanvas-header">
              <h2 className="offcanvas-title" id="offcanvasLabel">
                Menu
              </h2>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="p-4">
                <ul>
                  {loading && (
                    <div className="spinner-grow text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  {!loading && (
                    <>
                      {data.map((item, index) =>
                        index === 0 ? (
                          <li key={item.title} data-bs-dismiss="offcanvas">
                            <WavyLink to="/" color="#2853a3">
                              Home
                            </WavyLink>
                          </li>
                        ) : (
                          <li key={item.title} data-bs-dismiss="offcanvas">
                            <WavyLink
                              to={item.title.replace(/\s/g, "-").toLowerCase()}
                              color="#2853a3"
                            >
                              {item.title}
                            </WavyLink>
                          </li>
                        )
                      )}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <Outlet />
    </>
  );
};

export default Layout;
